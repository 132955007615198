<template>
  <legal-page-template :locale="locale" :scroll-to-id="scrollToId">
    <main class="legal-page__content legal-page-content">
      <h1 class="legal-page-content__title">{{ $t('legal.termsOfSales.title') }}</h1>
      <i18n tag="div" path="legal.termsOfSales.entry" class="legal-page-content__description">
        <a class="legal-page-content__link" href="https://skinxs.com" target="_blank"
          >https://skinxs.com</a
        >
        <b>{{ $t('legal.termsOfSales.universkin') }}</b>
      </i18n>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.firstArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.firstArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.inTermsOfSales') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfSales.firstTermOrder') }}</b>
            {{ $t('legal.termsOfSales.firstTermMeaning') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfSales.secondTermProduct') }}</b>
            {{ $t('legal.termsOfSales.secondTermMeaning') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfSales.thirdTermPlatform') }}</b>
            <i18n tag="span" path="legal.termsOfSales.thirdTermMeaning">
              <a class="legal-page-content__link" href="https://skinxs.com" target="_blank"
                >https://skinxs.com</a
              >
            </i18n>
          </p>

          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfSales.fourthTermService') }}</b>
            <i18n tag="span" path="legal.termsOfSales.fourthTermMeaning">
              <b>{{ $t('legal.termsOfSales.doctor') }}</b>
            </i18n>
          </p>

          <p class="legal-article-section__paragraph">
            <b>{{ $t('legal.termsOfSales.fifthTermUser') }}</b>
            {{ $t('legal.termsOfSales.fifthTermMeaning') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.secondArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.secondArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.termsOfSalesApplicable') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.anyOrderIsSubject') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.universkinReservesRight') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.termsOfSalesDirectlyAccessible') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.thirdArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.thirdArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.universkinProposesOnPlatform') }}
          </p>

          <i18n
            tag="p"
            path="legal.termsOfSales.productsSpeciallyPrepared"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.termsOfSales.personalizedProducts') }}</b>
          </i18n>

          <i18n
            tag="p"
            path="legal.termsOfSales.cosmeticsWithCommonFormulation"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.termsOfSales.commonProducts') }}</b>
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.productsMadeAvailable') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.detailedDescriptionOfEachProduct') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.fourthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.fourthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.creationOfAccount') }}
          </p>

          <i18n
            tag="p"
            path="legal.termsOfSales.placingAnOrder"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.termsOfSales.account') }}</b>
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userThenInvited') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userAcceptUseOfElectronicMessaging') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userInformedThatAccount') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.choiceOfProducts') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userCanSelectProducts') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.shoppingCartSummarizes') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.deliveryAndBillingAddress') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.deliveryCarriedOutHome') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userInvitedEnterDeliveryAddress') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.onceInformationVerified') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.payment') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.duringLastStep') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userCanCheckNumberOfProducts') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.onceVerificationMade') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userCanValidateOrder') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.orderDeemedFirm') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.emailAcknowledgingReceipt') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.anyModificationToOrder') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.fifthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.fifthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.price') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.priceOfProductsDisplayed') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.deliveryCostsMayApply') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.totalAmountForOrder') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.priceDueInFullOnDate') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.paymentTerms') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.ordersPayableByBankCard') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.noBankDataRequestedOrStored') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.invoicesIssuedUponDelivery') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.guaranteesGrantedByUniverskin') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.universkinCanInNoWay') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.orderEffectiveAfterAgreement') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.delayInPaymentResult') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.anyComplaintByUser') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.retentionOfTitle') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.itExpresslyAgreedThatUniverskinRetains') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.sixthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.sixthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.deliveryTerms') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.productsAvailableOnPlatform') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.universkinCanAtItsOptionMakeSuccessive') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.notwithstandingRetentionOfTitle') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.informationProvidedDuringOrder') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.universkinReleasedFromItsDelivery') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.deliveryDelay') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.deliveryTimesSpecifiedWhenPlacingOrder') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.absenceOrDelayInDelivery') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.itPossibleThatPackageNotArrive') }}
          </p>

          <i18n
            tag="p"
            path="legal.termsOfSales.userHasMaximumPeriod"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >.
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.ifUserDeclaredHisPackageLost') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.responseCanBeOfTwoTypes') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.reception') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.uponDeliveryOfProducts') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userMustIndicateUponReceipt') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.inEventOfApparentDefect') }}
          </p>

          <i18n
            tag="p"
            path="legal.termsOfSales.ifProductsNeedToBeReturned"
            class="legal-article-section__paragraph"
          >
            <b>{{ $t('legal.termsOfSales.userAcknowledgesAndAccepts') }}</b>
          </i18n>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.seventhArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.seventhArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.noRightOfWithdrawal') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userNotHaveRightWithdrawal') }}
          </p>
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSales.rightOfWithdrawalForCommon') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userHasRightOfWithdrawalForCommonProducts') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.periodForExercising') }}
          </p>

          <p class="legal-article-section__paragraph--bold">
            {{ $t('legal.termsOfSales.rightOfWithdrawalCannotBeExercised') }}
          </p>

          <i18n
            tag="p"
            path="legal.termsOfSales.exerciseRightOfWithdrawal"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.inEventOfExerciseOfRightOfWithdrawal') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.eighthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.eighthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userAcknowledgesAndAcceptsThatAllRights') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.termsOfSalesNotGiveUserAnyRight') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.ninthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.ninthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.termsOfSales.universkinConcernedWithProtection"
            class="legal-article-section__paragraph"
          >
            <a
              class="legal-page-content__link"
              href="https://www.skinxs.com/patient-notice"
              target="_blank"
              >https://www.skinxs.com/patient-notice</a
            >
          </i18n>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div id="article10" class="legal-page-article__header">
          {{ $t('legal.termsOfSales.tenthArticle') }}
        </div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.tenthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.termsOfSales.productsSoldOnPlatformBenefit"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >
          </i18n>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.inAdditionPersonalizedProducts') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.activateCommercialGuarantee') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(I)</b>
            {{ $t('legal.termsOfSales.situationsGivingRise') }}
          </p>
          <p class="legal-article-section__paragraph">
            <b>&bull;</b>
            {{ $t('legal.termsOfSales.absenceOfResultsAfterFourMonth') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>(II)</b>
            {{ $t('legal.termsOfSales.activateOfGuarantee') }}
          </p>
          <p class="legal-article-section__paragraph">
            <b>&bull;</b>
            {{ $t('legal.termsOfSales.atHisChoiceUserInvited') }}
          </p>
          <p class="legal-article-section__paragraph">
            <b>&bull;</b>
            <i18n tag="span" path="legal.termsOfSales.emailSentToUserByUniverskin">
              <a
                class="legal-page-content__link"
                href="https://satisfactionguaranteed.skinxs.com"
                target="_blank"
                >https://satisfactionguaranteed.skinxs.com</a
              >
            </i18n>
          </p>

          <p class="legal-article-section__paragraph">
            <b>(III)</b>
            {{ $t('legal.termsOfSales.conditionsOfComplaint') }}
          </p>
          <p class="legal-article-section__paragraph">
            <b>&bull;</b>
            {{ $t('legal.termsOfSales.providePhotographWithProof') }}
          </p>
          <p class="legal-article-section__paragraph">
            <b>&bull;</b>
            {{ $t('legal.termsOfSales.identifyOtherProducts') }}
          </p>
          <p class="legal-article-section__paragraph">
            <b>&bull;</b>
            {{ $t('legal.termsOfSales.returnPersonalizedProduct') }}
          </p>
          <p class="legal-article-section__paragraph">
            <b>&bull;</b>
            {{ $t('legal.termsOfSales.carryOutNewSkinAnalysis') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.universkinNotGrantAnyOtherExpress') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.eleventhArticle') }}</div>
        <div class="legal-page-article__title">{{ $t('legal.termsOfSales.eleventhArticle') }}</div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.byExpressAgreementUniverskinSubject') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.productsDescribedAndOresented') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.universkinCaoontHeldLiable') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.inCaseOfDelayOrInabilityDeliver') }}
          </p>
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.inCaseOfUserErrorInHisValidatedOrder') }}
          </p>
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.inCaseOfFaultOrFailureOfUserHandling') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.userExpresslyAcknowledgesThatUseOfProducts') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSales.twelfthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.twelfthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSales.responsibilityOfUniverskinCannotBeEngaged') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">
          {{ $t('legal.termsOfSales.thirteenthArticle') }}
        </div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSales.thirteenthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            <b>13.1.</b>
            {{ $t('legal.termsOfSales.termsOfSalesConstituteEntireAgreement') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>13.2.</b>
            {{ $t('legal.termsOfSales.termsOfSalesConcludedIntuitu') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>13.3.</b>
            {{ $t('legal.termsOfSales.inEventThatOneOrMoreProvisions') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>13.4.</b>
            {{ $t('legal.termsOfSales.failureForEitherOfParties') }}
          </p>

          <p class="legal-article-section__paragraph">
            <b>13.5.</b>
            {{ $t('legal.termsOfSale.archivingOfCommunicationsOrderAndInvoices') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">
          {{ $t('legal.termsOfSale.fourteenthArticle') }}
        </div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSale.fourteenthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSale.termsOfSalesSubjectToFrenchLaw') }}
          </p>

          <p class="legal-article-section__paragraph">
            {{ $t('legal.termsOfSale.partiesDeclareTheirIntention') }}
          </p>

          <i18n
            tag="p"
            path="legal.termsOfSale.notwithstandingForegoingUserDomiciled"
            class="legal-article-section__paragraph"
          >
            <a
              class="legal-page-content__link"
              href="https://medicys-consommation.fr/"
              target="_blank"
              >https://www.cnpm-mediation-consommation.eu/</a
            >
          </i18n>
        </div>
      </div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSale.fifteenthArticle') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSale.fifteenthArticleTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <i18n
            tag="p"
            path="legal.termsOfSale.forAnyRequestRelatingToCurrentOrder"
            class="legal-article-section__paragraph"
          >
            <a href="mailto:info@universkin.com" class="legal-page-content__link"
              >info@universkin.com</a
            >
          </i18n>
        </div>
      </div>

      <div class="legal-page-content__divider"></div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSale.firstAppendix') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSale.firstAppendixTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__paragraph">
            <i18n tag="span" path="legal.termsOfSale.letter.toAttentionOfUniverskin">
              <a href="mailto:info@universkin.com" class="legal-page-content__link"
                >info@universkin.com</a
              >
            </i18n>
            <br />
            <br />
            <br />
            <span class="word-break-all">
              {{ $t('legal.termsOfSale.letter.ILastNameFirstName') }}
              _________________________________________________
              {{ $t('legal.termsOfSale.letter.undersigned') }}
            </span>
            <br />
            <br />
            <br />
            {{ $t('legal.termsOfSale.letter.notifyMyWithdrawalFromContact') }}
            <br />
            <br />
            {{ $t('legal.termsOfSale.letter.orderNumber') }} _______________________
            <br />
            <br />
            {{ $t('legal.termsOfSale.letter.dateOfOrder') }} __________________
            <br />
            <br />
            {{ $t('legal.termsOfSale.letter.deliveryDate') }}
            ______________________
            {{ $t('legal.termsOfSale.letter.ifApplicable') }}
            <br />
            <br />
            <br />
            {{ $t('legal.termsOfSale.letter.signature') }}
            <br />
            <br />
            <br />
            {{ $t('legal.termsOfSale.letter.date') }}
          </p>
        </div>
      </div>

      <div class="legal-page-content__divider"></div>

      <div class="legal-page-content__article legal-page-article">
        <div class="legal-page-article__header">{{ $t('legal.termsOfSale.secondAppendix') }}</div>
        <div class="legal-page-article__title">
          {{ $t('legal.termsOfSale.secondAppendixTitle') }}
        </div>

        <div class="legal-page-article__section legal-article-section">
          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSale.firstArticleLFrenchConsumer') }}
          </p>

          <p class="legal-article-section__paragraph--italic">
            « {{ $t('legal.termsOfSale.sellerDeliversGoodsThatComply') }}
          </p>
          <p
            class="legal-article-section__paragraph--italic legal-article-section__paragraph--space-after"
          >
            {{ $t('legal.termsOfSale.heAlsoResponsibleForAnyLackOfConformity') }} »
          </p>

          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSale.secondArticleLFrenchConsumer') }}
          </p>

          <p class="legal-article-section__paragraph--italic">
            « {{ $t('legal.termsOfSale.goodsComplyWithContract') }}
          </p>

          <p class="legal-article-section__paragraph--italic">
            {{ $t('legal.termsOfSale.ifItSuitableForUse') }}
          </p>
          <p class="legal-article-section__paragraph--italic">
            {{ $t('legal.termsOfSale.ifItCorrespondsToDescription') }}
          </p>
          <p class="legal-article-section__paragraph--italic">
            {{ $t('legal.termsOfSale.ifItHasQualitiesThatBuyer') }}
          </p>

          <p
            class="legal-article-section__paragraph--italic legal-article-section__paragraph--space-after"
          >
            {{ $t('legal.termsOfSale.orIfitHasCharacteristicsDefined') }} »
          </p>

          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSale.thirdArticleLFrenchConsumer') }}
          </p>
          <p
            class="legal-article-section__paragraph--italic legal-article-section__paragraph--space-after"
          >
            « {{ $t('legal.termsOfSale.actionResultingFromLackOfConformity') }} »
          </p>

          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSale.firstArticleFrenchCivil') }}
          </p>
          <p
            class="legal-article-section__paragraph--italic legal-article-section__paragraph--space-after"
          >
            « {{ $t('legal.termsOfSale.sellerBoundByGuaranteeForHiddenDefects') }} »
          </p>

          <p class="legal-article-section__subtitle">
            {{ $t('legal.termsOfSale.secondArticleFrenchCivil') }}
          </p>
          <p class="legal-article-section__paragraph--italic">
            « {{ $t('legal.termsOfSale.actionResultingFromLatentDefects') }} »
          </p>
        </div>
      </div>
    </main>
  </legal-page-template>
</template>

<script>
import LegalPageTemplate from '@/components/templates/LegalPageTemplate';

export default {
  name: 'TermsOfSalesPage',
  components: { LegalPageTemplate },
  props: {
    locale: {
      type: String,
      default: ''
    },
    scrollToId: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/legal-pages/content-styles';
</style>
